import React from "react"
import * as Icon from "react-feather"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import CaseDetail from "../../components/Shared/casedetails"

const seoTitle = "Amazon Web Services To Google Cloud Platform"
const pageTitle = "Amazon Web Services To Google Cloud Platform"
const subTitle = "Cloud"
const para =
`Building “Cloud Factory” pattern to migrate the entire suite of applications form Amazon Web Services (AWS) to <a href="/google-cloud-service">Google Cloud Platform (GCP)</a> by breaking down extensive, complex systems into discrete “waves”.`
const blockquote = `"Migration from AWS to GCP"`

const cardItems = [
  {
    id: 1,
    heading: `Migration of all apps from AWS to GCP
    (HashiCorp Vault & TFE)`,
    listItems: (
      <>
        <p>
          {" "}
          A “wave” is defined by the identification of complimentary systems and
          the creation of tools and processes that work together in a systematic
          way. Each “wave” builds upon the previous framework created, and
          lessons learned, to ensure that each asset aligns to the defined
          architecture, governance, and best practices of the organization as to
          accelerate the overall migration of these assets to the new cloud
          environment. We are using TFE for provisioning compute, storage and
          networks on GCP and Ansible for post-production deployments/
          configuration management. Also, we are migrating secrets that are
          sprawled across various cloud providers into centralized HashiCorp
          Vault instances that is DR and PR enabled.
        </p>
      </>
    ),
  },
  {
    id: 2,
    heading: `The Need`,
    listItems: (
      <>
        <p>
          The client wanted to migrate all their apps currently deployed on AWS
          to GCP. They wanted to use TFE and Vault Enterprise to streamline the
          entire process.
        </p>
      </>
    ),
  },
  {
    id: 3,
    heading: `The Approach`,
    listItems: (
      <>
        <p>
          We utilized TFE features like workspaces and private modules and
          organizations to create reusable private modules to provision
          infrastructure on GCP. Enterprise Vault to migrate secrets from AWS
          and other sources.
        </p>
      </>
    ),
  },
  {
    id: 3,
    heading: `The Process`,
    listItems: (
      <>
        <p>
        We created “Cloud Factory” patterns along with respective TFE
            reusable modules to migrate applications from AWS to GCP. Also, we
            utilized Enterprise Vault to migrate KV’s from AWS Secrets manager.
            We have plans to use PKI engine to generate certificates for mTLS
            communication
        </p>
      </>
    ),
  },
  {
    id: 2,
    heading: `Result`,
    listItems: (
      <>
        <p>
          TFE is successfully deployed in production and working on the
          operationalizing Enterprise Vault in the coming quarters.
        </p>
      </>
    ),
  },
]

const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
export const query = graphql`
  query DanAndBradstreetQuery {
    casedetailsJson(slug: { eq: "amazon-web-services-to-google-cloud-platform" }) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
